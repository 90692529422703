import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import TopBarProgress from "react-topbar-progress-indicator";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import Sidebar from "../components/sidebar.component";
import Footer from "../components/footer.component";
import Navbar from "../components/navbar.component";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import { useNavigate } from "react-router-dom";
import "./crm.css";
import { DOMAIN, DOMAIN_SERVER, scrapeData } from "../../utils/states";
import { useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
const ExcelJS = require("exceljs");

const cl = {};

const getLocalStorage = [];
const setLocalStorage = [];

const paginationOptions = {
  rowsPerPageText: "Rows per page",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

const styles = {
  drawerContent: {
    padding: "20px",
    width: "315px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  title: {
    margin: 0,
  },
  closeButton: {
    background: "transparent",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
  backButton: {
    background: "transparent",
    border: "none",
    fontSize: "16px",
    color: "#007bff",
    marginBottom: "10px",
    cursor: "pointer",
  },
  sectionTitle: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  filterOptionList: {
    display: "flex",
    flexDirection: "column",
  },
  filterOption: {
    textAlign: "left",
    padding: "10px",
    margin: "5px 0",
    background: "#f5f5f5",
    border: "1px solid #ddd",
    borderRadius: "5px",
    cursor: "pointer",
  },
  filterOptions: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    borderTop: "1px solid #ddd",
    background: "#fff",
  },
  cancelButton: {
    background: "#f5f5f5",
    border: "1px solid #ddd",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  applyButton: {
    background: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
};

const sortDataByRating = (data, ascending) => {
  return [...data].sort((a, b) => {
    const aRatings = a.rating
      ? parseFloat(String(a.rating).replace(/,/g, ""))
      : 0;
    const bRatings = b.rating
      ? parseFloat(String(b.rating).replace(/,/g, ""))
      : 0;

    return ascending ? aRatings - bRatings : bRatings - aRatings;
  });
};

const sortDataByReview = (data, ascending) => {
  return [...data].sort((a, b) => {
    const aReviews = a.reviews
      ? parseFloat(String(a.reviews).replace(/,/g, ""))
      : 0;
    const bReviews = b.reviews
      ? parseFloat(String(b.reviews).replace(/,/g, ""))
      : 0;

    return ascending ? aReviews - bReviews : bReviews - aReviews;
  });
};

const sortDataByPhone = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAPhone = a.phone ? 1 : 0;
    const hasBPhone = b.phone ? 1 : 0;

    if (ascending) {
      return hasAPhone - hasBPhone;
    } else {
      return hasBPhone - hasAPhone;
    }
  });
};

const sortDataByEmail = (data, ascending) => {
  return [...data].sort((a, b) => {
    const countAEmails = Array.isArray(a.emails) ? a.emails.length : 0; // Count emails in a
    const countBEmails = Array.isArray(b.emails) ? b.emails.length : 0; // Count emails in b

    if (countAEmails > 1 && countBEmails > 1) return 0; // Both have more than one email
    if (countAEmails > 1) return ascending ? -1 : 1; // A has more than one email
    if (countBEmails > 1) return ascending ? 1 : -1; // B has more than one email
    if (countAEmails === 1 && countBEmails === 1) return 0; // Both have one email
    if (countAEmails === 1) return ascending ? -1 : 1; // A has one email
    if (countBEmails === 1) return ascending ? 1 : -1; // B has one email

    return 0; // Both have none or don't meet the criteria
  });
};

const sortDataByZip = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAZip = a.zip ? 1 : 0;
    const hasBZip = b.zip ? 1 : 0;

    if (ascending) {
      return hasAZip - hasBZip;
    } else {
      return hasBZip - hasAZip;
    }
  });
};

const sortDataByStreet = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAStreet = a.street ? 1 : 0;
    const hasBStreet = b.street ? 1 : 0;

    if (ascending) {
      return hasAStreet - hasBStreet;
    } else {
      return hasBStreet - hasAStreet;
    }
  });
};

const sortDataByCity = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasACity = a.city ? 1 : 0;
    const hasBCity = b.city ? 1 : 0;

    if (ascending) {
      return hasACity - hasBCity;
    } else {
      return hasBCity - hasACity;
    }
  });
};

const sortDataByState = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAState = a.state ? 1 : 0;
    const hasBState = b.state ? 1 : 0;

    if (ascending) {
      return hasAState - hasBState;
    } else {
      return hasBState - hasAState;
    }
  });
};

const sortDataByFacebook = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAFacebookLink = a.social?.some((link) =>
      link.includes("facebook.com")
    )
      ? 1
      : 0;
    const hasBFacebookLink = b.social?.some((link) =>
      link.includes("facebook.com")
    )
      ? 1
      : 0;

    if (ascending) {
      return hasAFacebookLink - hasBFacebookLink;
    } else {
      return hasBFacebookLink - hasAFacebookLink;
    }
  });
};

const sortDataByInstagram = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAInstagramLink = a.social?.some((link) =>
      link.includes("instagram.com")
    )
      ? 1
      : 0;
    const hasBInstagramLink = b.social?.some((link) =>
      link.includes("instagram.com")
    )
      ? 1
      : 0;

    if (ascending) {
      return hasAInstagramLink - hasBInstagramLink;
    } else {
      return hasBInstagramLink - hasAInstagramLink;
    }
  });
};

const sortDataByTikTok = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasATikTokLink = a.social?.some((link) => link.includes("tiktok.com"))
      ? 1
      : 0;
    const hasBTikTokLink = b.social?.some((link) => link.includes("tiktok.com"))
      ? 1
      : 0;

    if (ascending) {
      return hasATikTokLink - hasBTikTokLink;
    } else {
      return hasBTikTokLink - hasATikTokLink;
    }
  });
};

const sortDataByWebsite = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAWebsite = a.website ? 1 : 0;
    const hasBWebsite = b.website ? 1 : 0;

    if (ascending) {
      return hasAWebsite - hasBWebsite;
    } else {
      return hasBWebsite - hasAWebsite;
    }
  });
};

const sortDataByGmb = (data, ascending) => {
  return [...data].sort((a, b) => {
    const hasAGmb = a.website ? 1 : 0;
    const hasBGmb = b.website ? 1 : 0;

    if (ascending) {
      return hasAGmb - hasBGmb;
    } else {
      return hasBGmb - hasAGmb;
    }
  });
};

class Crm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      syncEmails: false,
      currentSyncCursor: 0,
      emailsNeedsToSync: 0,
      data: [],
      queries: [],
      count: {},
      currentQuery:
        localStorage.getItem("currentQuery") === null
          ? ""
          : localStorage.getItem("currentQuery"),
      email: localStorage.getItem("email"),
      isContactableLeads: false,
      totalRows: 0,
      perPage: 30,

      allDataExport: 0,
      totalDataExport: 0,

      folders: [],

      sortRatingAscending: null,
      sortReviewAscending: null,
      sortPhoneAscending: null,
      sortEmailAscending: null,
      sortZipAscending: null,
      sortStreetAscending: null,
      sortCityAscending: null,
      sortStateAscending: null,
      sortFacebookAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,

      isDrawerOpen: false,
      currentFilter: null,
      phoneFilter: null,
      streetFilter: null,
    };
  }

  removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  async componentDidMount() {
    await this.syncFolders();
    this.fetchGmb(1);
  }

  handleRatingSort = () => {
    const newSortOrder = !this.state.sortRatingAscending;

    const sortedData = sortDataByRating(this.state.data, newSortOrder);

    this.setState({
      sortRatingAscending: newSortOrder,
      sortReviewAscending: null,
      sortPhoneAscending: null,
      sortEmailAscending: null,
      sortZipAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      data: sortedData,
    });
  };

  handleReviewSort = () => {
    const newSortOrder = !this.state.sortReviewAscending;

    const sortedData = sortDataByReview(this.state.data, newSortOrder);

    this.setState({
      sortRatingAscending: null,
      sortPhoneAscending: null,
      sortEmailAscending: null,
      sortZipAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortReviewAscending: newSortOrder,
      data: sortedData,
    });
  };

  handlePhoneSort = () => {
    const newSortOrder = !this.state.sortPhoneAscending;

    const sortedData = sortDataByPhone(this.state.data, newSortOrder);

    this.setState({
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortZipAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortPhoneAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleEmailSort = () => {
    const newSortOrder = !this.state.sortEmailAscending;

    const sortedData = sortDataByEmail(this.state.data, newSortOrder);

    this.setState({
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortPhoneAscending: null,
      sortZipAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortEmailAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleZipSort = () => {
    const newSortOrder = !this.state.sortZipAscending;

    const sortedData = sortDataByZip(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortZipAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleCitySort = () => {
    const newSortOrder = !this.state.sortCityAscending;

    const sortedData = sortDataByCity(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortZipAscending: null,
      sortStreetAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortCityAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleStreetSort = () => {
    const newSortOrder = !this.state.sortStreetAscending;

    const sortedData = sortDataByStreet(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortZipAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortStreetAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleStateSort = () => {
    const newSortOrder = !this.state.sortStateAscending;

    const sortedData = sortDataByState(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortZipAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortStateAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleFacebookSort = () => {
    const newSortOrder = !this.state.sortFacebookAscending;

    const sortedData = sortDataByFacebook(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortZipAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      sortFacebookAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleInstagramSort = () => {
    const newSortOrder = !this.state.sortInstagramAscending;

    const sortedData = sortDataByInstagram(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortZipAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: newSortOrder,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      data: sortedData,
    });
  };

  handleTikTokSort = () => {
    const newSortOrder = !this.state.sortTikTokAscending;

    const sortedData = sortDataByTikTok(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortZipAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: newSortOrder,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: null,
      data: sortedData,
    });
  };

  handleWebsiteSort = () => {
    const newSortOrder = !this.state.sortWebsiteAscending;

    const sortedData = sortDataByWebsite(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortZipAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortGmbAscending: null,
      sortWebsiteAscending: newSortOrder,
      data: sortedData,
    });
  };

  handleGmbSort = () => {
    const newSortOrder = !this.state.sortGmbAscending;

    const sortedData = sortDataByGmb(this.state.data, newSortOrder);

    this.setState({
      sortPhoneAscending: null,
      sortRatingAscending: null,
      sortReviewAscending: null,
      sortEmailAscending: null,
      sortCityAscending: null,
      sortStreetAscending: null,
      sortZipAscending: null,
      sortStateAscending: null,
      sortInstagramAscending: null,
      sortTikTokAscending: null,
      sortFacebookAscending: null,
      sortWebsiteAscending: null,
      sortGmbAscending: newSortOrder,
      data: sortedData,
    });
  };

  updateGmbFolder = async (folder, data) => {
    try {
      await this.setStateAsync(this, {
        loading: true,
      });

      var payload = {
        folder,
        ...data,
      };

      await axios.post(`${DOMAIN_SERVER}/api/update-single-gmb-folder`, {
        ...payload,
      });

      var newData = this.state.data.map((g) => {
        return {
          ...g,
          folder: g._id === data._id ? folder : g?.folder || "",
        };
      });

      await this.setStateAsync(this, {
        data: newData,
        loading: false,
      });

      return true;
    } catch (error) {
      console.log(error);

      await this.setStateAsync(this, {
        loading: false,
      });
    }
  };

  syncFolders = async () => {
    var userId = localStorage.getItem("user_id");
    var folders = [];

    if (
      typeof userId === "undefined" ||
      userId === "" ||
      userId === "null" ||
      userId === null
    ) {
      localStorage.removeItem("email");
      window.location.href = "/";
    } else {
      var foldersRes = await axios.get(
        `${DOMAIN_SERVER}/api/get-folders/${userId}`
      );

      folders = foldersRes.data || [];
    }

    await this.setStateAsync(this, {
      folders,
    });

    return folders;
  };

  downloadXLSX = () => {
    return (
      <button
        className="btn btn-sm btn-info"
        disabled={this.state.data && this.state.data.length ? false : true}
        onClick={() => {
          this.pushToExcel();
        }}
      >
        {this.state.excelExportStart
          ? "Downloading Excel (" +
            this.state.allDataExport +
            " / " +
            this.state.totalDataExport +
            ")"
          : "Download Excel"}
      </button>
    );
  };

  back = () => {
    return (
      <button
        className="btn btn-sm btn-info"
        onClick={() => {
          this.props.navigate("/dashboard");
        }}
      >
        <i className="fas fa-arrow-left mr-1"></i> Back
      </button>
    );
  };

  filter = () => {
    return (
      <button className="btn btn-sm btn-info" onClick={this.toggleDrawer}>
        Filter
      </button>
    );
  };

  removeListData = () => {
    return (
      <button
        className="btn btn-sm btn-danger"
        onClick={() => {
          this.clearData();
        }}
        disabled={this.state.data && this.state.data.length ? false : true}
      >
        Remove Data
      </button>
    );
  };

  hunterPopup() {
    return (
      <button
        className="btn btn-sm btn-info"
        style={{ fontSize: 13 }}
        onClick={() => {
          ModalManager.open(
            <HunterIO onRequestClose={() => false} data={this.state.data} />
          );
        }}
      >
        Find emails from Hunter.io
      </button>
    );
  }

  setStateAsync = (component, newState) => {
    return new Promise((resolve) => {
      component.setState(newState, () => {
        resolve();
      });
    });
  };

  syncEmailsHandler = async () => {
    function isValidURL(url) {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }

    await this.setStateAsync(this, { syncEmails: true });

    let currentPage = 1;
    let totalPages = 1;

    try {
      do {
        // Fetch each page of data
        const response = await axios.post(`${DOMAIN_SERVER}/api/get-gmb`, {
          user: this.state.email,
          nicheId: this.state.currentQuery,
          page: currentPage,
          perpage: 500,
        });

        const result = response.data;
        const pageData = result.data; // Current page's data
        totalPages = result.total_pages; // Total pages from API
        currentPage++; // Increment page counter

        // Update state for pagination progress
        await this.setStateAsync(this, {
          totalDataSync: Number(result?.total)?.toLocaleString(),
          emailsNeedsToSync: Number(result?.total)?.toLocaleString(),
          currentPageSync: currentPage - 1,
        });

        // Filter the data to find those that need to be synced (emailSync is undefined or false)
        const totalNeedsToSync = pageData.filter(
          (e) => !e.emailSync || e.emailSync === "0" || e.emailSync === 0
        );
        await this.setStateAsync(this, {
          // emailsNeedsToSync: totalNeedsToSync.length,
          data: pageData,
        });

        // Process and sync emails for each element in the current page
        for (let index = 0; index < pageData.length; index++) {
          const element = pageData[index];
          await this.setStateAsync(this, { currentSyncCursor: index + 1 });

          // Check if the website is valid and emailSync is false or undefined
          console.log(1);

          if (
            isValidURL(element.website) &&
            (typeof element.emailSync === "undefined" ||
              element.emailSync === false ||
              element.emailSync === "0" ||
              element.emailSync === 0)
          ) {
            console.log(2);

            try {
              let url = new URL(element.website);
              let domain = url.origin;

              const scrapeResponse = await axios.post(
                `https://lead-gorilla-cdn-1-1.onrender.com/scrape-email`,
                {
                  id: element.id,
                  domain,
                }
              );

              // Update data with the response
              await this.updateData(scrapeResponse.data);
            } catch (error) {
              console.error("Error processing URL:", element.website);
              console.error(error);
            }
          }
        }
      } while (currentPage <= totalPages);

      // Set syncEmails to false when done
      await this.setStateAsync(this, { syncEmails: false });
      this.componentDidMount(); // Refresh data or do necessary updates
    } catch (err) {
      console.error("Error fetching email data from the server:", err);
      await this.setStateAsync(this, { syncEmails: false });
    }
  };

  updateData = async (data) => {
    var payload = {
      ...data,
      emailSync: true,
    };

    await axios.post(`${DOMAIN_SERVER}/api/update-single-gmb`, {
      id: payload.id,
      emails: payload?.emails[0].emails || [],
      social: payload?.emails[0].socialLinks || [],
    });

    return true;
  };

  gmlEmailFinder() {
    return this.state.syncEmails ? (
      <button
        className="btn btn-sm btn-danger"
        style={{ fontSize: 13 }}
        onClick={() => {
          this.setState({ syncEmails: false }, () => {
            window.location.reload();
          });
        }}
      >
        Stop Syncing Emails
        <div
          className="spinner-border"
          role="status"
          style={{
            height: 16,
            width: 16,
            marginLeft: 10,
          }}
        >
          <span className="sr-only">Loading...</span>
        </div>{" "}
        ({this.state.currentSyncCursor} / {this.state.emailsNeedsToSync})
      </button>
    ) : (
      <button
        className="btn btn-sm btn-info"
        style={{ fontSize: 13 }}
        onClick={() => {
          this.syncEmailsHandler();
        }}
      >
        Find emails & Social Links
      </button>
    );
  }

  ghlExportBtn() {
    return (
      <button
        className="btn btn-sm btn-info"
        style={{ fontSize: 13 }}
        onClick={async () => {
          const ghlApiKey = localStorage.getItem("ghlApiKey") || "";
          if (typeof ghlApiKey === "undefined" || ghlApiKey === "") {
            alert(`Please connect GHL account in "GHL Settings"`);

            this.props.navigate("/integrations");
            return false;
          }
          ModalManager.open(
            <GhlExport onRequestClose={() => false} data={this.state.data} />
          );
        }}
      >
        GHL Export
      </button>
    );
  }

  async pushToExcelOld() {
    if (this.state.excelExportStart === true) {
      return;
    }

    this.setState({ excelExportStart: true });

    try {
      let currentPage = 1;
      let result = null;
      var newTransformedData = [];

      do {
        const response = await axios.post(`${DOMAIN_SERVER}/api/get-gmb`, {
          user: this.state.email,
          nicheId: this.state.currentQuery,
          page: currentPage,
          perpage: 250,
        });

        result = response.data;

        // Transform data dynamically
        var transformedData = result.data.map((item) => {
          const emails = item.emails ? item.emails.join(", ") : "";
          const facebook = item.social
            .filter((link) => link.includes("facebook.com"))
            .join(", ");
          const instagram = item.social
            .filter((link) => link.includes("instagram.com"))
            .join(", ");
          const tiktok = item.social
            .filter((link) => link.includes("tiktok.com"))
            .join(", ");

          return {
            ...item, // Include all fields from the original item
            "Company Name": item.title,
            emails,
            Facebook: facebook,
            Instagram: instagram,
            TikTok: tiktok,
          };
        });

        transformedData.forEach((item) => {
          delete item.title;
          delete item.emailSync;
          delete item.social;
          delete item._id;
          delete item.nicheId;
        });

        newTransformedData = newTransformedData.concat(transformedData);

        this.setState({
          totalDataExport: Number(result?.total)?.toLocaleString(),
          allDataExport: Number(currentPage * 250)?.toLocaleString(),
        });

        currentPage++;
      } while (currentPage <= result?.total_pages);

      const chunkSize = 10000;
      const workbook = XLSX.utils.book_new();

      for (let i = 0; i < newTransformedData.length; i += chunkSize) {
        const chunk = newTransformedData.slice(i, i + chunkSize);
        const worksheet = XLSX.utils.json_to_sheet(chunk);
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `Sheet${i / chunkSize + 1}`
        );
      }

      XLSX.writeFile(workbook, "Lead-Gorilla.xlsx");

      this.setState({ excelExportStart: false });
    } catch (err) {
      console.error(err);
      this.setState({ excelExportStart: false });
    }
  }

  async pushToExcel() {
    if (this.state.excelExportStart === true) {
      return;
    }

    this.setState({ excelExportStart: true });

    try {
      let currentPage = 1;
      let result = null;
      const maxRetries = 10; // Maximum retries for a failed request

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Leads");

      let headers = null;

      while (true) {
        let retries = 0;
        let success = false;

        while (retries < maxRetries && !success) {
          try {
            // Make the API request
            const response = await axios.post(`${DOMAIN_SERVER}/api/get-gmb`, {
              user: this.state.email,
              nicheId: this.state.currentQuery,
              page: currentPage,
              perpage: 1000,
            });

            result = response.data;
            success = true; // Mark request as successful
          } catch (error) {
            retries++;
            console.error(
              `Failed to fetch page ${currentPage}. Retrying (${retries}/${maxRetries})...`,
              error
            );

            if (retries >= maxRetries) {
              console.error(
                `Skipping page ${currentPage} after ${maxRetries} failed attempts.`
              );
            }
          }
        }

        // If the page couldn't be retrieved, skip it
        if (!success) {
          currentPage++;
          continue;
        }

        // Transform data dynamically
        const transformedData = result.data.map((item) => {
          const emails = item.emails ? item.emails.join(", ") : "";
          const facebook = item.social
            .filter((link) => link.includes("facebook.com"))
            .join(", ");
          const instagram = item.social
            .filter((link) => link.includes("instagram.com"))
            .join(", ");
          const tiktok = item.social
            .filter((link) => link.includes("tiktok.com"))
            .join(", ");

          return {
            ...item,
            "Company Name": item.title,
            emails,
            Facebook: facebook,
            Instagram: instagram,
            TikTok: tiktok,
          };
        });

        transformedData.forEach((item) => {
          delete item.title;
          delete item.emailSync;
          delete item.social;
          delete item._id;
          delete item.nicheId;
          delete item.folder;
        });

        if (!headers && transformedData.length > 0) {
          headers = Object.keys(transformedData[0]);
          worksheet.addRow(headers).commit();
        }

        transformedData.forEach((row) => {
          worksheet.addRow(Object.values(row)).commit();
        });

        this.setState({
          totalDataExport: Number(result?.total)?.toLocaleString(),
          allDataExport: Number(currentPage * 1000)?.toLocaleString(),
        });

        currentPage++;

        // Break the loop if all pages are processed
        if (currentPage > result?.total_pages) {
          break;
        }
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Lead-Gorilla.xlsx";
      link.click();

      this.setState({ excelExportStart: false });
      console.log("Excel file exported successfully!");
    } catch (err) {
      console.error(err);
      this.setState({ excelExportStart: false });
    }
  }

  clearData = async () => {
    await this.setStateAsync(this, { loading: true });

    await axios.post(`${DOMAIN_SERVER}/api/delete-niche`, {
      user: this.state.email,
      niche: this.state.currentQuery,
    });

    await this.setStateAsync(this, { loading: false });

    this.props.navigate("/dashboard");
  };

  navigate = (query) => {
    this.setState({ currentQuery: query }, () => {
      const temp = [];
      this.state.data.forEach((e) => {
        if (e.query === query) {
          temp.push(e);
        }
      });
      this.setState({ data: temp });
    });
  };

  remove = (item) => {
    var temp = [];
    var temp1 = [];
    this.state.queries.forEach((element) => {
      if (element !== item) {
        temp.push(element);
      }
    });
    this.state.data.forEach((element1) => {
      if (element1.query !== item) {
        temp1.push(element1);
      }
    });
    cl.set({ queries: temp, scrapeData: temp1 }, () => {
      window.location.reload();
    });
  };

  logout = () => {
    localStorage.removeItem("profile");
    window.location.href = "/login";
  };

  fetchGmb = async (page) => {
    this.setState({ loading: true });
    try {
      const response = await axios.post(`${DOMAIN_SERVER}/api/get-gmb`, {
        user: this.state.email,
        nicheId: this.state.currentQuery,
        page,
        perpage: this.state.perPage,
      });

      const scrapeData = response.data.data;
      var newData = [];

      for (let index = 0; index < scrapeData.length; index++) {
        var element = scrapeData[index];
        element.reviews = isNaN(+element.reviews) ? 0 : +element.reviews;
        element.rating = isNaN(+element.rating) ? 0 : +element.rating;

        if (this.state.isContactableLeads === true) {
          if (element?.emails?.length > 0 || element?.phone?.length > 0) {
            newData.push(element);
          }
        } else {
          newData.push(element);
        }
      }

      if (this.state.sortRatingAscending !== null) {
        newData = sortDataByRating(newData, this.state.sortRatingAscending);
      }

      if (this.state.sortReviewAscending !== null) {
        newData = sortDataByReview(newData, this.state.sortReviewAscending);
      }

      if (this.state.sortPhoneAscending !== null) {
        newData = sortDataByPhone(newData, this.state.sortPhoneAscending);
      }

      if (this.state.sortEmailAscending !== null) {
        newData = sortDataByEmail(newData, this.state.sortEmailAscending);
      }

      if (this.state.sortZipAscending !== null) {
        newData = sortDataByZip(newData, this.state.sortZipAscending);
      }

      if (this.state.sortCityAscending !== null) {
        newData = sortDataByCity(newData, this.state.sortCityAscending);
      }

      if (this.state.sortStreetAscending !== null) {
        newData = sortDataByStreet(newData, this.state.sortStreetAscending);
      }

      if (this.state.sortStateAscending !== null) {
        newData = sortDataByState(newData, this.state.sortStateAscending);
      }

      if (this.state.sortFacebookAscending !== null) {
        newData = sortDataByFacebook(newData, this.state.sortFacebookAscending);
      }

      if (this.state.sortInstagramAscending !== null) {
        newData = sortDataByInstagram(
          newData,
          this.state.sortInstagramAscending
        );
      }

      if (this.state.sortTikTokAscending !== null) {
        newData = sortDataByTikTok(newData, this.state.sortTikTokAscending);
      }

      if (this.state.sortWebsiteAscending !== null) {
        newData = sortDataByWebsite(newData, this.state.sortWebsiteAscending);
      }

      if (this.state.sortGmbAscending !== null) {
        newData = sortDataByGmb(newData, this.state.sortGmbAscending);
      }

      if (this.state.phoneFilter === "isEmpty") {
        newData = newData.filter((item) => item.phone === "");
      }

      if (this.state.phoneFilter === "isNotEmpty") {
        newData = newData.filter((item) => item.phone !== "");
      }

      if (this.state.streetFilter === "isEmpty") {
        newData = newData.filter((item) => item.street === "");
      }

      if (this.state.streetFilter === "isNotEmpty") {
        newData = newData.filter((item) => item.street !== "");
      }

      this.setState({
        data: newData,
        totalRows: response.data.total,
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ loading: false });
    }
  };

  contactableLeadsToggle = () => {
    return (
      <label style={{ fontSize: 16, marginRight: 50 }}>
        <span
          style={{
            fontWeight: "bold",
            verticalAlign: "super",
            paddingTop: 7,
            marginRight: 12,
          }}
        >
          Contactable Leads:{" "}
          <i
            title="Hide leads that are missing both email and phone number."
            className="fa fa-info-circle"
            ariaHidden="true"
            style={{ verticalAlign: "text-bottom", color: "#2c9faf" }}
          ></i>{" "}
        </span>
        <Toggle
          className="custom-toggle"
          checked={this.state.isContactableLeads}
          onChange={this.handleContactableLeadsToggle}
        />
      </label>
    );
  };

  handleContactableLeadsToggle = () => {
    this.setState(
      { isContactableLeads: !this.state.isContactableLeads },
      () => {
        this.componentDidMount();
      }
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.fetchGmb(this.state.currentPage);
      }
    );
  };

  handlePerRowsChange = async (newPerPage, page) => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          const response = await axios.post(`${DOMAIN_SERVER}/api/get-gmb`, {
            user: this.state.email,
            nicheId: this.state.currentQuery,
            page,
            perpage: newPerPage,
          });

          var newData = response.data.data;

          if (this.state.sortRatingAscending !== null) {
            newData = sortDataByRating(newData, this.state.sortRatingAscending);
          }

          if (this.state.sortReviewAscending !== null) {
            newData = sortDataByReview(newData, this.state.sortReviewAscending);
          }

          if (this.state.sortPhoneAscending !== null) {
            newData = sortDataByPhone(newData, this.state.sortPhoneAscending);
          }

          this.setState({
            data: newData,
            perPage: newPerPage,
            totalRows: response.data.total,
            loading: false,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          this.setState({ loading: false });
        }
      }
    );
  };

  toggleDrawer = () => {
    this.setState((prevState) => ({
      isDrawerOpen: !prevState.isDrawerOpen,
      currentFilter: null,
    }));
  };

  openFilterOptions = (filter) => {
    this.setState({ currentFilter: filter });
  };

  handleApply = () => {
    this.toggleDrawer();
  };

  handleCancel = () => {
    this.toggleDrawer();
  };

  render() {
    TopBarProgress.config({
      barColors: {
        0: "#CB3837",
        0.5: "#C836C3",
        "1.0": "#29ABE2",
      },
      shadowBlur: 5,
    });

    const { data, loading, totalRows, perPage, isDrawerOpen, currentFilter } =
      this.state;

    const columns = [
      {
        name: <div style={{ minWidth: 200 }}>Company Name</div>,
        selector: "title",
        sortable: false,
        cell: (row) => (
          <div
            style={{
              fontSize: 12,
              minWidth: 150,

              maxWidth: 150, // Ensures the column width is fixed
              whiteSpace: "nowrap", // Prevents text from wrapping to the next line
              overflow: "hidden", // Hides the overflowing text
              textOverflow: "ellipsis",
            }}
          >
            {row?.title}
          </div>
        ),
      },
      {
        name: (
          <div
            onClick={this.handlePhoneSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: 50,
              marginRight: 50,
            }}
          >
            Phone
            {this.state.sortPhoneAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortPhoneAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5, marginLeft: 50 }}
              />
            )}
          </div>
        ),
        selector: "phone",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, minWidth: "120px", marginLeft: 50 }}>
            {row?.phone}
          </div>
        ),
      },
      {
        name: (
          <div
            onClick={this.handleCitySort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: 30,
            }}
          >
            City
            {this.state.sortCityAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortCityAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "city",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, minWidth: "120px", marginLeft: 30 }}>
            {row?.city}
          </div>
        ),
      },

      {
        name: (
          <div
            onClick={this.handleStreetSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: 7,
            }}
          >
            Street
            {this.state.sortStreetAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortStreetAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "street",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, minWidth: "200px", marginLeft: 7 }}>
            {row?.street}
          </div>
        ),
      },
      {
        name: (
          <div
            onClick={this.handleStateSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: 52,
            }}
          >
            State
            {this.state.sortStateAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortStateAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "state",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, minWidth: "120px", marginLeft: 52 }}>
            {row?.state}
          </div>
        ),
      },
      {
        name: (
          <div
            onClick={this.handleZipSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -2,
            }}
          >
            Zip
            {this.state.sortZipAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortZipAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "zip",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, minWidth: "120px", marginLeft: -2 }}>
            {row?.zip}
          </div>
        ),
      },
      {
        name: (
          <div
            onClick={this.handleRatingSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -59,
            }}
          >
            Rating
            {this.state.sortRatingAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortRatingAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "rating",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, minWidth: "120px", marginLeft: -41 }}>
            {row.rating}
          </div>
        ),
      },
      {
        name: (
          <div
            onClick={this.handleReviewSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -100,
            }}
          >
            Reviews
            {this.state.sortReviewAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortReviewAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "reviews",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, minWidth: "120px", marginLeft: -76 }}>
            {row.reviews}
          </div>
        ),
      },
      {
        name: (
          <div
            onClick={this.handleEmailSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -137,
            }}
          >
            Email
            {this.state.sortEmailAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortEmailAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "emails",
        sortable: false,
        cell: (row) => <EmailCell row={row} />,
      },
      {
        name: (
          <div
            onClick={this.handleWebsiteSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -50,
            }}
          >
            Website
            {this.state.sortWebsiteAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortWebsiteAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "website",
        sortable: false,
        cell: (row) => (
          <div
            style={{
              fontSize: 12,
              textAlign: "center",
              minWidth: "120px",
              marginLeft: -75,
            }}
          >
            {row?.website !== "" &&
            typeof row?.website !== "undefined" &&
            row?.website?.length > 0 ? (
              <a href={row.website} target="_blank" rel="noopener noreferrer">
                <i className="fas fa-link" />
              </a>
            ) : (
              "-"
            )}
          </div>
        ),
      },

      {
        name: (
          <div
            onClick={this.handleFacebookSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -70,
            }}
          >
            Facebook
            {this.state.sortFacebookAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortFacebookAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: -70 }}
              />
            )}
          </div>
        ),
        selector: "facebook",
        sortable: false,
        cell: (row) => {
          const facebookLinks =
            row?.social?.filter((link) => link.includes("facebook.com")) || [];

          return (
            <div
              onClick={this.handleFacebookSort}
              style={{ cursor: "pointer" }}
            >
              {facebookLinks.length > 0 ? (
                facebookLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "7px" }}
                  >
                    <i className="fab fa-facebook" style={{ fontSize: 16 }} />
                  </a>
                ))
              ) : (
                <span style={{ marginLeft: -32 }}>-</span>
              )}
            </div>
          );
        },
      },
      {
        name: (
          <div
            onClick={this.handleInstagramSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -70,
            }}
          >
            Instagram
            {this.state.sortInstagramAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortInstagramAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: -70 }}
              />
            )}
          </div>
        ),
        selector: "instagram",
        sortable: false,
        cell: (row) => {
          const instagramLinks =
            row?.social?.filter((link) => link.includes("instagram.com")) || [];

          return (
            <div
              onClick={this.handleInstagramSort}
              style={{ cursor: "pointer" }}
            >
              {instagramLinks.length > 0 ? (
                instagramLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "7px" }}
                  >
                    <i className="fab fa-instagram" style={{ fontSize: 16 }} />
                  </a>
                ))
              ) : (
                <span style={{ marginLeft: -32 }}>-</span>
              )}
            </div>
          );
        },
      },
      {
        name: (
          <div
            onClick={this.handleTikTokSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -70,
            }}
          >
            TikTok
            {this.state.sortTikTokAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortTikTokAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: -70 }}
              />
            )}
          </div>
        ),
        selector: "tiktok",
        sortable: false,
        cell: (row) => {
          const tiktokLinks =
            row?.social?.filter((link) => link.includes("tiktok.com")) || [];

          return (
            <div
              onClick={this.handleTikTokSort}
              style={{ cursor: "pointer", marginLeft: -70 }}
            >
              {tiktokLinks.length > 0 ? (
                tiktokLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "7px" }}
                  >
                    <svg
                      style={{ fontSize: 17 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="0.88em"
                      height="1em"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M448 209.91a210.06 210.06 0 0 1-122.77-39.25v178.72A162.55 162.55 0 1 1 185 188.31v89.89a74.62 74.62 0 1 0 52.23 71.18V0h88a121 121 0 0 0 1.86 22.17A122.18 122.18 0 0 0 381 102.39a121.43 121.43 0 0 0 67 20.14Z"
                      />
                    </svg>
                  </a>
                ))
              ) : (
                <span style={{ marginLeft: 30 }}>-</span>
              )}
            </div>
          );
        },
      },

      {
        name: (
          <div
            onClick={this.handleGmbSort}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              marginLeft: -70,
            }}
          >
            GMB Link
            {this.state.sortGmbAscending !== null && (
              <i
                className={`fas fa-sort-${
                  this.state.sortGmbAscending === true ? "up" : "down"
                }`}
                style={{ marginLeft: 5 }}
              />
            )}
          </div>
        ),
        selector: "gmb",
        sortable: false,
        cell: (row) => (
          <div style={{ fontSize: 12, textAlign: "center", marginLeft: -35 }}>
            {row.gmb !== "" && typeof row.gmb !== "undefined" ? (
              <a href={row.gmb} target="_blank" rel="noopener noreferrer">
                <i className="fas fa-link" />
              </a>
            ) : (
              "-"
            )}
          </div>
        ),
      },
    ];

    const customStyles = {
      headCells: {
        style: {
          fontWeight: "bold",
        },
      },
    };

    return (
      <div id="wrapper">
        <Drawer
          open={isDrawerOpen}
          onClose={this.toggleDrawer}
          direction="right"
          className="drawer-style"
          style={{ width: 315 }}
        >
          <div style={styles.drawerContent}>
            <div style={styles.header}>
              <h5 style={styles.title}>
                {currentFilter ? "Filters" : "Apply filters to contacts"}
              </h5>
              <button style={styles.closeButton} onClick={this.toggleDrawer}>
                ✖
              </button>
            </div>

            {/* Render filter options or main filter list */}
            {currentFilter ? (
              <div>
                {/* Back button */}
                <button
                  style={styles.backButton}
                  onClick={() => this.setState({ currentFilter: null })}
                >
                  ← Back
                </button>
                <h4 style={styles.sectionTitle}>{currentFilter}</h4>

                {/* Phone filter options */}
                {currentFilter === "Phone" && (
                  <div style={styles.filterOptions}>
                    <label>
                      <input
                        type="radio"
                        name="phoneFilter"
                        value="isEmpty"
                        style={{ marginRight: 8 }}
                        onChange={() =>
                          this.setState({ phoneFilter: "isEmpty" })
                        }
                      />
                      Is empty
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="phoneFilter"
                        value="isNotEmpty"
                        style={{ marginRight: 8 }}
                        onChange={() =>
                          this.setState({ phoneFilter: "isNotEmpty" })
                        }
                      />
                      Is not empty
                    </label>
                  </div>
                )}

                {/* Street filter options */}
                {currentFilter === "Street" && (
                  <div style={styles.filterOptions}>
                    <label>
                      <input
                        type="radio"
                        name="streetFilter"
                        value="isEmpty"
                        style={{ marginRight: 8 }}
                        onChange={() =>
                          this.setState({ streetFilter: "isEmpty" })
                        }
                      />
                      Is empty
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="streetFilter"
                        value="isNotEmpty"
                        style={{ marginRight: 8 }}
                        onChange={() =>
                          this.setState({ streetFilter: "isNotEmpty" })
                        }
                      />
                      Is not empty
                    </label>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ marginTop: 25 }}>
                {/* Main filter list */}
                <h4 style={styles.sectionTitle}>Most Used</h4>
                <div style={styles.filterOptionList}>
                  <button
                    style={styles.filterOption}
                    onClick={() => this.openFilterOptions("Phone")}
                  >
                    Phone ➡
                  </button>
                  {/* Add more filters here */}
                  <button
                    style={styles.filterOption}
                    onClick={() => this.openFilterOptions("Street")}
                  >
                    Address ➡
                  </button>
                </div>
              </div>
            )}

            {/* Footer */}
            <div style={styles.footer}>
              <button style={styles.cancelButton} onClick={this.handleCancel}>
                Cancel
              </button>
              <button style={styles.applyButton} onClick={this.handleApply}>
                Apply
              </button>
            </div>
          </div>
        </Drawer>

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar current="crm" />

            <div className="container-fluid">
              <Fragment>
                {this.state.loading && <TopBarProgress />}

                {this.back()}

                <DataTable
                  columns={columns}
                  fixedHeader
                  customStyles={customStyles}
                  data={this.state.data.map((item, index) => ({
                    ...item,
                    key: `${item._id}-${index}`,
                  }))}
                  keyField="_id"
                  sortable={true}
                  actions={[
                    this.contactableLeadsToggle(),
                    this.filter(),
                    this.ghlExportBtn(),
                    this.gmlEmailFinder(),
                    this.hunterPopup(),
                    this.downloadXLSX(),
                  ]}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[20, 50, 100]}
                  paginationComponentOptions={paginationOptions}
                />
              </Fragment>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

function CrmPage() {
  const navigate = useNavigate();

  return <Crm navigate={navigate} />;
}

export default CrmPage;

class HunterIO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hunterApiKey: localStorage.getItem("hunterApiKey") || "",
      scrapeData: props.data,
      scan: 0,
      allScrapeData: [],
    };
  }

  syncEmails = async (element) => {
    const { hunterApiKey } = this.state;

    if (element.website && element.website.length > 5) {
      try {
        const res = await axios.get(
          `https://api.hunter.io/v2/domain-search?domain=${element.website}&api_key=${hunterApiKey}`
        );
        return res.data.data.emails;
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    return [];
  };

  save = () => {
    cl.set(
      {
        tombaApiKey: this.state.tombaApiKey,
        tombaApiSecret: this.state.tombaApiSecret,
      },
      () => {
        const { scrapeData, allScrapeData } = this.state;
        if (scrapeData.length === 0) {
          alert("No GMB available!");
          return;
        }
        let updated = [];
        this.setState({ loading: true }, async () => {
          for (let index = 0; index < scrapeData.length; index++) {
            var element = scrapeData[index];
            if (
              typeof element.website !== "undefined" &&
              element.website.indexOf("aclk?") === -1 &&
              element.emails?.length === 0
            ) {
              const emails = await this.syncEmails(element);
              element.emails = emails;

              if (emails.length > 0) {
                updated.push(element);
              }
            } else {
              updated.push(element);
            }
            this.setState({ scan: index + 1 });
          }

          const dict1 = {};
          allScrapeData.forEach((item) => {
            dict1[item.uuid] = item;
          });

          scrapeData.forEach((item) => {
            const uuid = item.uuid;
            if (dict1[uuid]) {
              Object.assign(dict1[uuid], item);
            } else {
              allScrapeData.push(item);
            }
          });

          cl.set({ scrapeData: allScrapeData }, () => {
            window.location.reload();
          });
        });
      }
    );
  };

  render() {
    const { onRequestClose, data } = this.props;
    return (
      <Modal onRequestClose={onRequestClose} effect={Effect.ScaleUp}>
        <div className="modal-header">
          <h4 className="modal-title">Find emails using Hunter.io</h4>
        </div>
        <div className="modal-body" style={{ height: 400 }}>
          <div className="col col-lg-12 col-md-12" style={{ display: "flex" }}>
            <div className="col col-md-12">
              <input
                className="form-control"
                onChange={(e) => {
                  this.setState(
                    {
                      hunterApiKey: e.target.value,
                    },
                    () => {
                      cl.set({ hunterApiKey: this.state.hunterApiKey });
                    }
                  );
                }}
                value={
                  this.state.hunterApiKey.length > 0
                    ? this.state.hunterApiKey.substring(0, 5) + "*****"
                    : ""
                }
                placeholder="Hunter.io API Key"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div>
            <label style={{ marginLeft: "-31%", fontSize: 18 }}>
              Finding emails:{" "}
              {`${this.state.scan} / ${this.state.scrapeData?.length}`}
            </label>
          </div>
          <button
            type="button"
            id="__close"
            className="btn btn-success"
            onClick={this.save}
          >
            Start Finding Emails
          </button>
          <button
            type="button"
            id="__close"
            className="btn btn-default"
            onClick={ModalManager.close}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

class GhlExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      ghlLocations: [],
      ghlApiKey: "",
      currentNumber: 0,
      selectedLocation: "",
      tags: null,
      selectedTags: [],
      exportStart: false,

      currentQuery:
        localStorage.getItem("currentQuery") === null
          ? ""
          : localStorage.getItem("currentQuery"),
      email: localStorage.getItem("email"),

      totalDataExport: 0,
      allDataExport: 0,

      customFields: null,

      companyNameField: "companyName",
      emailField: "email",
      phoneField: "phone",
      streetField: "street",
      cityField: "city",
      stateField: "state",
      zipField: "postalCode",
      websiteField: "website",
      facebookField: "",
      instagramField: "",
      tiktokField: "",
      gmbField: "",

      ratingField: "",
      reviewField: "",
    };
  }

  async componentDidMount() {
    const ghlLocations = JSON.parse(localStorage.getItem("ghlLocations")) || [];
    const ghlApiKey = localStorage.getItem("ghlApiKey");

    await this.setStateAsync(this, {
      ghlLocations: ghlLocations || [],
      ghlApiKey: ghlApiKey || "",
    });
  }

  setStateAsync = (component, newState) => {
    return new Promise((resolve) => {
      component.setState(newState, () => {
        resolve();
      });
    });
  };

  handleSelectChange = (newValue) => {
    this.setState({ selectedTags: newValue?.map((e) => e.value) });
  };

  fetchTags = async () => {
    await this.setStateAsync(this, {
      exportStart: true,
    });

    const location = this.state.ghlLocations.find(
      (e) => e.id === this.state.selectedLocation
    );

    const headers = {
      Authorization: `Bearer ${location.apiKey}`,
      "Content-Type": "application/json",
    };

    const url = `https://rest.gohighlevel.com/v1/tags/`;

    const response = await axios.get(url, { headers });

    const tags = response.data?.tags?.map((e) => ({
      label: e.name,
      value: e.name,
    }));

    const urlCustomFields = `https://rest.gohighlevel.com/v1/custom-fields/`;

    const responseCustomFields = await axios.get(urlCustomFields, {
      headers: headers,
    });

    let customFields = [
      { id: "companyName", name: "Company Name" },
      { id: "phone", name: "Phone" },
      { id: "city", name: "City" },
      { id: "street", name: "Street" },
      { id: "state", name: "State" },
      { id: "zip", name: "Zip" },
      { id: "rating", name: "Rating" },
      { id: "reviews", name: "Reviews" },
      { id: "email", name: "Email" },
      { id: "website", name: "Website" },
      { id: "facebook", name: "Facebook" },
      { id: "instagram", name: "Instagram" },
      { id: "tiktok", name: "TikTok" },
      { id: "gmb", name: "GMB Link" },
    ];

    let customFieldsNew = responseCustomFields.data?.customFields?.map((e) => ({
      name: e.name,
      id: e.id,
    }));

    customFields = customFields.concat(customFieldsNew);

    await this.setStateAsync(this, {
      tags,
      customFields: customFields?.length > 0 ? customFields : null,
      exportStart: false,
    });

    return true;
  };

  export = async () => {
    if (this.state.exportStart) {
      return;
    }

    this.setState({ exportStart: true });

    try {
      let currentPage = 1;
      let totalPages = 1;

      do {
        const response = await axios.post(`${DOMAIN_SERVER}/api/get-gmb`, {
          user: this.state.email,
          nicheId: this.state.currentQuery,
          page: currentPage,
          perpage: 500,
        });

        const result = response.data;

        await this.setStateAsync(this, {
          totalDataExport: Number(result?.total)?.toLocaleString(),
        });

        totalPages = result.total_pages;

        for (const _data of result.data) {
          try {
            await this.setStateAsync(this, {
              currentNumber: this.state.currentNumber + 1,
            });

            if (typeof _data.title !== "undefined" && _data.title !== "") {
              const url = "https://rest.gohighlevel.com/v1/contacts/";
              const location = this.state.ghlLocations.find(
                (e) => e.id === this.state.selectedLocation
              );

              if (_data.emails?.length > 0) {
                for (let index = 0; index < _data.emails.length; index++) {
                  var email = _data.emails[index];

                  var facebookLinks =
                    _data?.social?.filter((link) =>
                      link.includes("facebook.com")
                    ) || [];

                  var instagramLinks =
                    _data?.social?.filter((link) =>
                      link.includes("instagram.com")
                    ) || [];

                  var tiktokLinks =
                    _data?.social?.filter((link) =>
                      link.includes("tiktok.com")
                    ) || [];

                  var data = {
                    id: _data.id || "",
                    [this.state.emailField]: email,
                    [this.state.phoneField]:
                      index === 0 ? _data.phone || undefined : undefined,
                    name: _data.title || "",
                    [this.state.streetField]: _data.street || "",
                    [this.state.cityField]: _data.city || "",
                    [this.state.stateField]: _data.state || "",
                    country: "US",
                    [this.state.zipField]: _data.zip || "",
                    [this.state.companyNameField]: _data.title || "",
                    [this.state.websiteField]: _data.website || "",
                    [this.state.facebookField]: facebookLinks[0] || "",
                    [this.state.instagramField]: instagramLinks[0] || "",
                    [this.state.tiktokField]: tiktokLinks[0] || "",
                    [this.state.gmbField]: _data.gmb || "",
                    tags: this.state.selectedTags || "",
                    source: "Lead Gorilla",
                    customField: {
                      [this.state.ratingField]: _data.rating,
                      [this.state.reviewField]: _data.reviews,
                    },
                  };

                  var headers = {
                    Authorization: `Bearer ${location.apiKey}`,
                    "Content-Type": "application/json",
                  };

                  var r = await axios.post(url, data, { headers });

                  if (typeof r?.data?.contact?.id !== "undefined") {
                    await axios.put(url + r.data.contact.id, data, { headers });
                  }
                }
              } else {
                var facebookLinks =
                  _data?.social?.filter((link) =>
                    link.includes("facebook.com")
                  ) || [];

                var instagramLinks =
                  _data?.social?.filter((link) =>
                    link.includes("instagram.com")
                  ) || [];

                var tiktokLinks =
                  _data?.social?.filter((link) =>
                    link.includes("tiktok.com")
                  ) || [];

                var data = {
                  id: _data.id || "",
                  email: undefined,
                  [this.state.phoneField]: _data.phone || "",
                  name: _data.title || "",
                  [this.state.streetField]: _data.street || "",
                  [this.state.cityField]: _data.city || "",
                  [this.state.stateField]: _data.state || "",
                  country: "US",
                  [this.state.zipField]: _data.zip || "",
                  [this.state.companyNameField]: _data.title || "",
                  [this.state.websiteField]: _data.website || "",
                  [this.state.facebookField]: facebookLinks[0] || "",
                  [this.state.instagramField]: instagramLinks[0] || "",
                  [this.state.tiktokField]: tiktokLinks[0] || "",
                  [this.state.gmbField]: _data.gmb || "",
                  tags: this.state.selectedTags || "",
                  source: "Lead Gorilla",
                  customField: {
                    [this.state.ratingField]: _data.rating,
                    [this.state.reviewField]: _data.reviews,
                  },
                };

                var headers = {
                  Authorization: `Bearer ${location.apiKey}`,
                  "Content-Type": "application/json",
                };

                await axios.post(url, data, { headers });
              }

              await this.setStateAsync(this, {
                allDataExport: this.state.allDataExport + 1,
              });
            }
          } catch (error) {
            console.error("Error creating contact:", error);
          }
        }

        currentPage++; // Move to the next page

        // Update total data export count
        await this.setStateAsync(this, {
          allDataExport: Number(this.state.allDataExport)?.toLocaleString(),
        });
      } while (currentPage <= totalPages);

      toast("Leads exported!", { containerId: "B" });
    } catch (err) {
      console.log(err);
    } finally {
      await this.setStateAsync(this, { exportStart: false });
      this.props.onRequestClose();
      ModalManager.close();
    }
  };

  render() {
    const { onRequestClose, data } = this.props;
    const { ghlLocations } = this.state;
    return (
      <Modal
        onRequestClose={onRequestClose}
        effect={Effect.ScaleUp}
        style={{
          content: {
            height: 735,
            top: -171,
          },
        }}
      >
        <div className="modal-header">
          <h4 className="modal-title">Export data to GHL</h4>
          <button
            type="button"
            className="close"
            style={{
              background: "none",
              border: "none",
              fontSize: "2.7rem",
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "15px",
            }}
            aria-label="Close"
            onClick={ModalManager.close}
          >
            &times;
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col col-md-5">
              <select
                className="form-control"
                onChange={(e) => {
                  this.setState(
                    { selectedLocation: e.target.value, tags: null },
                    () => {
                      this.fetchTags();
                    }
                  );
                }}
              >
                <option>choose GHL location</option>
                {ghlLocations?.length > 0 &&
                  ghlLocations.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="col col-md-5">
              {this.state.tags !== null ? (
                <CreatableSelect
                  isMulti
                  options={this.state.tags}
                  onChange={this.handleSelectChange}
                  placeholder="Select tags..."
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: "200px",
                      overflowY: "auto",
                    }),
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          {this.state.customFields !== null ? (
            <>
              <br />
              <br />

              <div className="row">
                <div className="col col-md-12">
                  <h5>GHL Data Mapping</h5>
                </div>
                <div className="col col-md-12">
                  <div className="row">
                    <div className="col col-md-6">
                      <label>Local Field</label>
                      <select className="form-control" disabled>
                        <option value="companyName" selected>
                          Company Name
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="phone" selected>
                          Phone
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="city" selected>
                          City
                        </option>
                      </select>
                      <hr />

                      <select className="form-control" disabled>
                        <option value="street" selected>
                          Street
                        </option>
                      </select>
                      <hr />

                      <select className="form-control" disabled>
                        <option value="state" selected>
                          State
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="zip" selected>
                          Zip
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="rating" selected>
                          Rating
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="reviews" selected>
                          Reviews
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="email" selected>
                          Email
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="website" selected>
                          Website
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="facebook" selected>
                          Facebook Link
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="instagram" selected>
                          Instagram Link
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="tiktok" selected>
                          TikTok Link
                        </option>
                      </select>
                      <hr />
                      <select className="form-control" disabled>
                        <option value="gmb" selected>
                          GMB Link
                        </option>
                      </select>
                    </div>
                    <div className="col col-md-6">
                      <label>GHL Custom Field</label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ companyNameField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ phoneField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ cityField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ streetField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ stateField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ zipField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ ratingField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ reviewField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ emailField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ websiteField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ facebookField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ instagramField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ tiktokField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <hr />

                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ gmbField: e.target.value });
                        }}
                      >
                        <option value="">Choose GHL custom field</option>
                        {this.state.customFields.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div style={{ height: 0 }}>
            {this.state.exportStart ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{
                  margin: "auto",
                  background: "rgb(241, 242, 243)",
                  display: "block",
                  background: "none",
                }}
                width="200px"
                height="200px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="translate(50,50)">
                  <circle
                    cx={0}
                    cy={0}
                    r="6.25"
                    fill="none"
                    stroke="#93dbe9"
                    strokeWidth={4}
                    strokeDasharray="19.634954084936208 19.634954084936208"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      values="0 0 0;360 0 0"
                      times="0;1"
                      dur="1s"
                      calcMode="spline"
                      keySplines="0.2 0 0.8 1"
                      begin={0}
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle
                    cx={0}
                    cy={0}
                    r="12.5"
                    fill="none"
                    stroke="#689cc5"
                    strokeWidth={4}
                    strokeDasharray="39.269908169872416 39.269908169872416"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      values="0 0 0;360 0 0"
                      times="0;1"
                      dur="1s"
                      calcMode="spline"
                      keySplines="0.2 0 0.8 1"
                      begin="-0.14285714285714285"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle
                    cx={0}
                    cy={0}
                    r="18.75"
                    fill="none"
                    stroke="#5e6fa3"
                    strokeWidth={4}
                    strokeDasharray="58.90486225480862 58.90486225480862"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      values="0 0 0;360 0 0"
                      times="0;1"
                      dur="1s"
                      calcMode="spline"
                      keySplines="0.2 0 0.8 1"
                      begin="-0.2857142857142857"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle
                    cx={0}
                    cy={0}
                    r={25}
                    fill="none"
                    stroke="#3b4368"
                    strokeWidth={4}
                    strokeDasharray="78.53981633974483 78.53981633974483"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      values="0 0 0;360 0 0"
                      times="0;1"
                      dur="1s"
                      calcMode="spline"
                      keySplines="0.2 0 0.8 1"
                      begin="-0.42857142857142855"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle
                    cx={0}
                    cy={0}
                    r="31.25"
                    fill="none"
                    stroke="#191d3a"
                    strokeWidth={4}
                    strokeDasharray="98.17477042468103 98.17477042468103"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      values="0 0 0;360 0 0"
                      times="0;1"
                      dur="1s"
                      calcMode="spline"
                      keySplines="0.2 0 0.8 1"
                      begin="-0.5714285714285714"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle
                    cx={0}
                    cy={0}
                    r="37.5"
                    fill="none"
                    stroke="#d9dbee"
                    strokeWidth={4}
                    strokeDasharray="117.80972450961724 117.80972450961724"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      values="0 0 0;360 0 0"
                      times="0;1"
                      dur="1s"
                      calcMode="spline"
                      keySplines="0.2 0 0.8 1"
                      begin="-0.7142857142857143"
                      repeatCount="indefinite"
                    />
                  </circle>
                  <circle
                    cx={0}
                    cy={0}
                    r="43.75"
                    fill="none"
                    stroke="#b3b7e2"
                    strokeWidth={4}
                    strokeDasharray="137.44467859455344 137.44467859455344"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      values="0 0 0;360 0 0"
                      times="0;1"
                      dur="1s"
                      calcMode="spline"
                      keySplines="0.2 0 0.8 1"
                      begin="-0.8571428571428571"
                      repeatCount="indefinite"
                    />
                  </circle>
                </g>
              </svg>
            ) : (
              ""
            )}
          </div>
        </div>
        {this.state.selectedLocation !== "" && (
          <div className="modal-footer" style={{ marginTop: 200 }}>
            <div>
              <label style={{ marginLeft: "-31%", fontSize: 18 }}>
                Stats:{" "}
                {`${this.state.currentNumber} / ${this.state.totalDataExport}`}
              </label>
            </div>
            <button
              type="button"
              id="__close"
              className="btn btn-success"
              onClick={this.export}
              disabled={this.state.exportStart}
            >
              Export Leads
            </button>
            <button
              type="button"
              id="__close"
              className="btn btn-default"
              onClick={ModalManager.close}
            >
              Close
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

const EmailCell = ({ row }) => {
  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { emails } = row;

  const handleToggleClick = () => {
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
  };

  const truncateEmail = (email) => {
    return email;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 5,
        marginLeft: -135,
        fontSize: 12,
      }}
    >
      <span title={emails[0]}>{truncateEmail(emails[0])}</span>
      {emails?.length > 1 && (
        <span
          onClick={handleToggleClick}
          style={{ color: "blue", cursor: "pointer", fontSize: 12 }}
        >
          ... See More
        </span>
      )}

      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
            borderRadius: "8px",
            padding: "20px",
            zIndex: 1000,
            width: "300px",
          }}
        >
          <h4 style={{ margin: 0, marginBottom: "10px" }}>All Emails</h4>
          <ul style={{ padding: 0, listStyle: "none" }}>
            {emails.map((email, index) => (
              <li key={index} style={{ marginBottom: "5px" }}>
                {email}
              </li>
            ))}
          </ul>
          <button
            onClick={handleCloseModal}
            style={{
              marginTop: "10px",
              backgroundColor: "blue",
              color: "white",
              border: "none",
              borderRadius: "4px",
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            Close
          </button>
        </div>
      )}

      {/* Modal backdrop */}
      {showModal && (
        <div
          onClick={handleCloseModal}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
        ></div>
      )}
    </div>
  );
};
